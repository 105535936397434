import tier1 from './tier1fam.jpg';
import smallNeighborhood from './smallNeighborhood.png'
import './App.css';
import React from 'react';
import Tilt from 'react-parallax-tilt';


const App = () => {
  return (
    <Tilt>
      <div className='head-text' >
        <div>
          <a href="https://www.tier1gl.com" target="_blank" rel="noreferrer">
          <img src={tier1} className="App-logo" alt="logo" />
          </a>
        </div>
        
        <div className="text-on-image">
          <h1 >Tier 1 Gaming Lounge</h1>
        </div>
      
      </div>

    
      {/* <div className='head-text' >
        <div>
          <a href="https://www.smallneighborhood.com" target="_blank" rel="noreferrer">
          <img src={smallNeighborhood} className="App-logo" alt="logo" />
          </a>
        </div>
        
        <div className="text-on-image">
          <h1 >Small Neighborhood</h1>
        </div>
      
      </div>  */}
      </Tilt>
    
  );
};

// ReactDOM.render(<App />, document.getElementById('root'));
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Jordan Tian was here again.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
