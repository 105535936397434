
import smallNeighborhood from './smallNeighborhood.png'
import './App.css';
import React from 'react';
import Tilt from 'react-parallax-tilt';



const SmallNeighborhood = () => {
    return (
        <Tilt>
      <div className='head-text' >
        <div>
          <a href="https://www.smallneighborhood.com" target="_blank" rel="noreferrer">
          <img src={smallNeighborhood} className="App-logo" alt="logo" />
          </a>
        </div>
        
        <div className="text-on-image">
          <h1 >Small Neighborhood</h1>
        </div>
      
      </div> 
      </Tilt>
    )
}

export default SmallNeighborhood;